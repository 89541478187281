import * as React from 'react';
import Layout from '../components/layout'
import SEO from '../components/seo'
import CaseStudyContent from '../components/CaseStudyContent'


  const caseStudy: React.FC = () => (
    <Layout>
      <SEO title="Case Study" />
        <CaseStudyContent/>
    </Layout>
  );
  
  export default caseStudy;